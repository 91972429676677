<template>
    <section class="contenido p-mt-2">
        <TabMenu :model="items" class="tab-menu pb-3"  v-model:activeIndex="active" />
        <!-- <router-link to="/accounting/amortizations/dashboard/principal">Go to principal</router-link>
        <router-link to="/accounting/amortizations/dashboard/cupos-bancarios">Go to cupos</router-link> -->
        <router-view />
    </section>
</template>
<script>
import { ref } from 'vue'
export default {
    name: 'dashboardPrincipal',
    setup () {
        const active = ref(0)
        const items = ref([
            { label: 'Lineas de credito', icon: 'pi pi-fw pi-sitemap', to: '/pharmasan/contabilidad/amortizaciones/dashboard/principal' },
            { label: 'Cupos bancarios', icon: 'pi pi-fw pi-calendar', to: '/pharmasan/contabilidad/amortizaciones/dashboard/cupos-bancarios' },
            { label: 'Presupuesto', icon: 'pi pi-fw pi-table', to: '/pharmasan/contabilidad/amortizaciones/dashboard/presupuesto' }
        ])
        return {
            items,
            active
        }
    }
}
</script>
<style lang="scss">
.contenido {
  .p-component {
    font-size: small !important;
  }
  .p-tabmenuitem {
    white-space: nowrap;
  }
}
</style>
